// Login.jsx

// import loginImg from "../assets/Images/login.webp";
// import LottieAnimation from "../components/common/LottieAnimation";
import Template from "../components/core/Auth/Template";

function Login() {
  return (
    <Template
      title="Welcome Back"
      description1="Build skills for today, tomorrow, and beyond."
      description2="Education to future-proof your career."
      // img={LottieAnimation}
      condition="login"
      formType="login"
    />
  );
}

export default Login;
