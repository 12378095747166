export const HomePageExplore = [
  {
    tag: "Basic",
    courses: [
      {
        heading: "Learn HTML",
        description:
          "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
        level: "Beginner",
        lesson: 3,
      },
      {
        heading: "Learn CSS",
        description:
          "This course explores advanced topics in HTML5 and CSS3, including What is CSS?,Benefits and uses,CSS syntax and rules ",
        level: "Beginner",
        lesson: 6,
      },
      {
        heading: "Responsive Web design",
        description:
          "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
        level: "Beginner",
        lesson: 4,
      },
    ],
  },
  {
    tag: "New to coding",
    courses: [
      {
        heading: "HTML",
        description:
          "This course covers the basic to advanced concepts of HTML including creating and structuring web pages, Elements, attributes, and tags, Forms and user input",
        level: "Beginner",
        lesson: "10 days",
      },
      {
        heading: "CSS",
        description:
          "This course explores Basic to  advanced topics of CSS3, starting from What is CSS?,Benefits and uses, up to  animations, transitions, and layout techniques",
        level: "Beginner",
        lesson: 0.3,
      },
      {
        heading: "JavaScript ",
        description:
          "This course contains the each and every concepts of the JavaScript, starting from the data types to the filter, map, reduce and Dom manipulation",
        level: "Beginner",
        lesson: 0.5,
      },
    ],
  },
  {
    tag: "Most popular",
    courses: [
      {
        heading: "JavaScript",
        description:
          "This course contains the each and every concepts of the JavaScript, starting from the data types to the filter, map, reduce and Dom manipulation with multiple It Industry standards Projects",
        level: "Intermediate",
        lesson: 1,
      },
      {
        heading: "Node JS",
        description:
          "This is Run time Environment based ont he JavaScript Language, In which we can write the code of the backend of our website ",
        level: "Beginner",
        lesson: 1,
      },
      {
        heading: "React JS",
        description:
          "The IT Industry standard website made with the technology React JS, It helps to make the single Page Website ",
        level: "Advanced",
        lesson: 1,
      },
    ],
  },
  {
    tag: "Skills paths",
    courses: [
      {
        heading: "Next Js",
        description:
          "This course covers the basic concepts of HTML including creating and structuring web pages, adding text, links, images, and more.",
        level: "Beginner",
        lesson: 3,
      },
      {
        heading: "Django",
        description:
          "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
        level: "Beginner",
        lesson: 2,
      },
      {
        heading: "React Native",
        description:
          "This course teaches responsive web design techniques, allowing web pages to adapt to different devices and screen sizes",
        level: "Beginner",
        lesson: 3,
      },
    ],
  },
  {
    tag: "Career paths",
    courses: [
      {
        heading: "MERN STACK Development",
        description:
          "Master full-stack web development with the MERN stack. Learn MongoDB for NoSQL database management, Express.js for building robust web APIs, React for creating dynamic user interfaces, and Node.js for server-side development. ",
        level: "Advanced",
        lesson: 6,
      },
      {
        heading: "Manual + Automation",
        description:
          "This course explores advanced topics in HTML5 and CSS3, including animations, transitions, and layout techniques",
        level: "Beginner",
        lesson: 2,
      },
      {
        heading: "Java Development",
        description:
          "Learn the essentials of Java programming, covering core concepts and syntax, object-oriented programming, exception handling, file I/O, data structures, algorithms, and Java libraries and frameworks. ",
        level: "Beginner",
        lesson: 6,
      },
    ],
  },
];
