import TeamInfo from '../core/HomePage/TeamInfo'
import Footer from './Footer'

const OurInstructor = () => {
  return (
    <>
        <TeamInfo />
        <Footer />
    </>
  )
}

export default OurInstructor