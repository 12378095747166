// import React from "react";

const HighlightText = ({text}) => {
  return (
    <span className="bg-gradient-to-b 
    from-[#f5071f] via-[#F74A50] to-[#FA8E8E] 
   
    text-transparent bg-clip-text font-bold">
      {" "}
      {text}
    </span>
  );
};

export default HighlightText;


// from-[#f40752] via-[#F74B70] to-[#FA8E8E] 
// from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB] 