// import React from react;

import Footer from "../../components/common/Footer";
import "./style.css";

const Terms = () => {
  return (
    <>
      <main>
        <h1 className="header mb-12">Terms and Condition</h1>
        <div className="w-full">
          <h4 className="">Welcome to www.htslearn.com!</h4>

          <p className="pre">
            These terms and conditions outline the rules and regulations for the
            use of HTS Learn technologies pvt ltd Website, located at
            www.htslearn.com.
          </p>

          <p className="pre">
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use www.htslearn.com if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>
          <p className="pre">
            Course Pricing: Our course fees range from ₹5,000 to ₹500,000,
            depending on the specific course and level of instruction.
          </p>

          <p className="pre">
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements: Client,
            You and Your refers to you, the person log on this website and
            compliant to the Company terms and conditions. The Company,
            Ourselves, We, Our and Us, refers to our Company. Party, Parties, or
            Us, refers to both the Client and ourselves. All terms refer to the
            offer, acceptance and consideration of payment necessary to
            undertake the process of our assistance to the Client in the most
            appropriate manner for the express purpose of meeting the Clients
            needs in respect of provision of the Company stated services, in
            accordance with and subject to, prevailing law of in. Any use of the
            above terminology or other words in the singular, plural,
            capitalization and/or he/she or they, are taken as interchangeable
            and therefore as referring to same.
          </p>

          <h3 className="header">
            <strong>Cookies</strong>
          </h3>

          <p className="pre">
            We employ the use of cookies. By accessing www.htslearn.com, you
            agreed to use cookies in agreement with the HTS Learn technologies
            pvt ltds Privacy Policy.
          </p>

          <p className="pre">
            Most interactive websites use cookies to let us retrieve the users
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>

          <h3 className="header">
            <strong>License</strong>
          </h3>

          <p className="pre">
            Unless otherwise stated, HTS Learn technologies pvt ltd and/or its
            licensors own the intellectual property rights for all material on
            www.htslearn.com. All intellectual property rights are reserved. You
            may access this from www.htslearn.com for your own personal use
            subjected to restrictions set in these terms and conditions.
          </p>

          <h2 className="header">You must not:</h2>
          <ul>
            <li className="list">Republish material from www.htslearn.com</li>
            <li className="list">
              Sell, rent or sub-license material from www.htslearn.com
            </li>
            <li className="list">
              Reproduce, duplicate or copy material from www.htslearn.com
            </li>
            <li className="list">Redistribute content from www.htslearn.com</li>
          </ul>

          <p className="pre">
            This Agreement shall begin on the date hereof. Our Terms and
            Conditions were created with the help of the{" "}
            <a href="https://www.termsandconditionsgenerator.com/">
              Free Terms and Conditions Generator
            </a>
          </p>

          <p className="pre">
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            HTS Learn technologies pvt ltd does not filter, edit, publish or
            review Comments prior to their presence on the website. Comments do
            not reflect the views and opinions of HTS Learn technologies pvt
            ltd, its agents and/or affiliates. Comments reflect the views and
            opinions of the person who post their views and opinions. To the
            extent permitted by applicable laws, HTS Learn technologies pvt ltd
            shall not be liable for the Comments or for any liability, damages
            or expenses caused and/or suffered as a result of any use of and/or
            posting of and/or appearance of the Comments on this website.
          </p>

          <p className="pre">
            HTS Learn technologies pvt ltd reserves the right to monitor all
            Comments and to remove any Comments which can be considered
            inappropriate, offensive or causes breach of these Terms and
            Conditions.
          </p>

          <h2 className="header">You warrant and represent that:</h2>

          <ul>
            <li className="list">
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li className="list">
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </li>
            <li className="list">
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li className="list">
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>

          <p className="pre">
            You hereby grant HTS Learn technologies pvt ltd a non-exclusive
            license to use, reproduce, edit and authorize others to use,
            reproduce and edit any of your Comments in any and all forms,
            formats or media.
          </p>

          <h3 className="header">
            <strong>Hyperlinking to our Content</strong>
          </h3>

          <p className="pre">
            The following organizations may link to our Website without prior
            written approval:
          </p>

          <ul>
            <li className="list">Government agencies;</li>
            <li className="list">Search engines;</li>
            <li className="list">News organizations;</li>
            <li className="list">
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li className="list">
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Website.
            </li>
          </ul>

          <p className="pre">
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking partys site.
          </p>

          <h2 className="header">
            We may consider and approve other link requests from the following
            types of organizations:
          </h2>

          <ul>
            <li className="list">
              commonly-known consumer and/or business information sources;
            </li>
            <li className="list">dot.com community sites;</li>
            <li className="list">
              associations or other groups representing charities;
            </li>
            <li className="list">online directory distributors;</li>
            <li className="list">internet portals;</li>
            <li className="list">accounting, law and consulting firms; and</li>
            <li className="list">
              educational institutions and trade associations.
            </li>
          </ul>

          <p className="pre">
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of HTS Learn
            technologies pvt ltd; and (d) the link is in the context of general
            resource information.
          </p>

          <p className="pre">
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            partys site.
          </p>

          <p className="pre">
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to HTS Learn technologies pvt ltd. Please include
            your name, your organization name, contact information as well as
            the URL of your site, a list of any URLs from which you intend to
            link to our Website, and a list of the URLs on our site to which you
            would like to link. Wait 2-3 weeks for a response.
          </p>

          <h2 className="header">
            Approved organizations may hyperlink to our Website as follows:
          </h2>

          <ul>
            <li className="list">By use of our corporate name; or</li>
            <li className="list">
              By use of the uniform resource locator being linked to; or
            </li>
            <li className="list">
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking partys site.
            </li>
          </ul>

          <p className="pre">
            No use of HTS Learn technologies pvt ltds logo or other artwork will
            be allowed for linking absent a trademark license agreement.
          </p>

          <h3 className="header">
            <strong>iFrames</strong>
          </h3>

          <p className="pre">
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>

          <h3 className="header">
            <strong>Content Liability</strong>
          </h3>

          <p className="pre">
            We shall not be held responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>

          <h3 className="header">
            <strong>Reservation of Rights</strong>
          </h3>

          <p className="pre">
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and its linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>

          <h3 className="header">
            <strong>Removal of links from our website</strong>
          </h3>

          <p className="pre">
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us at any moment. We will
            consider requests to remove links but we are not obligated to do so
            or to respond to you directly.
          </p>

          <p className="pre">
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>

          <h3 className="header">
            <strong>Disclaimer</strong>
          </h3>

          <p className="pre">
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>

          <ul>
            <li className="list">
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li className="list">
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li className="list">
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li className="list">
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>

          <p className="pre">
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </p>

          <p className="pre">
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </p>
        </div>
        <div className="text-white">
          <h1 className="header">Introduction</h1>
          <p className="pre">
            Welcome to HTS LEARN. By using our services, you agree to comply
            with and be bound by the following terms and conditions. Please
            review these terms carefully. If you do not agree with these terms,
            you should not use our services.
          </p>
          <h1 className="header">Eligibility</h1>
          <p className="pre">
            To enroll in our courses, you must be at least 18 years old or have
            the permission of a parent or guardian.
          </p>
          <h1 className="header">Course Enrollment</h1>
          <p className="pre">
            Enrollment in our courses is subject to availability and the
            completion of any required prerequisites.
          </p>
          <h1 className="header">Payment</h1>
          <p className="pre">
            All course fees must be paid in full before the start of the course.
            We accept various payment methods, including credit/debit cards,
            bank transfers, and digital wallets.
          </p>
          <h1 className="header">Course Materials</h1>
          <p className="pre">
            All course materials provided are for your personal use only and
            must not be shared, copied, or distributed without our permission.
          </p>
          <h1 className="header">Code of Conduct</h1>
          <p className="pre">
            Students are expected to maintain a respectful and professional
            demeanor at all times. Disruptive behavior may result in dismissal
            from the course without a refund.
          </p>
          <h1 className="header">Certification</h1>
          <p className="pre">
            Certification will be provided upon successful completion of the
            course and any required assessments.
          </p>
          <h1 className="header">Liability</h1>
          <p className="pre">
            HTS LEARN is not liable for any direct, indirect, incidental, or
            consequential damages arising from the use of our services.
          </p>
          <h1 className="header">Changes to Terms</h1>
          <p className="pre">
            We reserve the right to modify these terms at any time. Any changes
            will be posted on our website and will take effect immediately upon
            posting.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Terms;
