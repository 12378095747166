// import React from "react";
import { FooterLink2 } from "../../data/footer-links";
import { Link } from "react-router-dom";

// Images
import Logo from "../../assets/Images/FoundingStory.jpeg";

// Icons
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaTwitter,
  FaYoutube
} from "react-icons/fa";

const BottomFooter = [
  { name: "Terms and Condition", path: "/term-and-conditions" },
  { name: "Privacy Policy", path: "/privacy-policy" },
  { name: "Refund & Return Policy", path: "/refund-policy" },
  { name: "Cancellation Policy", path: "/cancellation-policy" },
  { name: "Shipping Policy", path: "/shipping-policy" },
  // { name: "Cookie Policy", path: "/Cookie" },
];
const Resources = [
  { name: "Notes", path: "/notes" },
  { name: "cheat sheets", path: "/cheatSheet" },
  { name: "Projects", path: "/Projects" },
];
// const Plans = ["For Teachers", "For students"];
// const Community = ["Social Media", "Events"];

const Footer = () => {
  return (
    <div className="bg-richblack-800">
      <div className="flex lg:flex-row gap-8 items-center justify-between w-11/12 max-w-maxContent text-richblack-400 leading-6 mx-auto relative py-14">
        <div className="border-b w-[100%] flex flex-col lg:flex-row pb-5 border-richblack-700">
          {/* Section 1 */}
          <div className="lg:w-[35%]  flex flex-wrap flex-row justify-between lg:border-r lg:border-richblack-700 pl-3 lg:pr-5 gap-3">
            <div className="w-[30%] flex flex-col gap-3 lg:w-[30%] mb-7 lg:pl-0  ">
              <img
                src={Logo}
                alt=""
                className="object-contain w-[78%]  rounded-xl  inline-block "
              />
              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Company
              </h1>
              <div className="flex flex-col gap-2">
                {["About"].map((ele, i) => {
                  return (
                    <div
                      key={i}
                      className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.toLowerCase()}>{ele}</Link>
                    </div>
                  );
                })}
                <h1>GST NO. : 09AAHCH0448P1Z3</h1>
              </div>
              <div className="flex gap-3 text-lg">
                <Link
                  target="_blank"
                  to="https://www.facebook.com/htslearntechnologies/about_contact_and_basic_info/?id=61552541303764&open_field=website&sk=about_contact_and_basic_info"
                  className="hover:text-[#2272F4] hover:scale-125"
                >
                  <FaFacebook />
                </Link>
                <Link
                  target="_blank"
                  to="https://htslearn.com/"
                  className="hover:text-white hover:scale-125"
                >
                  <FaGoogle />
                </Link>
                <Link
                  target="_blank"
                  to="twitter.com/HTS_Learn"
                  className="hover:text-[#2272F4] hover:scale-125"
                >
                  <FaTwitter />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/@HTSlearn"
                  className="hover:text-[#EA0101] hover:scale-125"
                >
                  <FaYoutube />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/htslearn/"
                  className="hover:text-[#E1306C]  hover:scale-125"
                >
                  <FaInstagram />
                </Link>
              </div>
            
            </div>

            <div className="w-[48%] lg:w-[30%] mb-7 lg:pl-0">
              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Resources
              </h1>

              <div className="flex flex-col gap-2 mt-2">
                {Resources.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.path}>{ele.name}</Link>
                    </div>
                  );
                })}
              </div>

              <h1 className="text-richblack-50 font-semibold text-[16px] mt-7">
                Support
              </h1>
              <div className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200 mt-2">
                <Link to={"/help-center"}>Help Center</Link>
              </div>
            </div>

            {/* <div className="w-[48%] lg:w-[30%] mb-7 lg:pl-0 ">
              <h1 className="text-richblack-50 font-semibold text-[16px]">
                Plans
              </h1>

              <div className="flex flex-col gap-2 mt-2">
                {Plans.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.split(" ").join("-").toLowerCase()}>
                        {ele}
                      </Link>
                    </div>
                  );
                })}
              </div>
              <h1 className="text-richblack-50 font-semibold text-[16px] mt-7">
                Community
              </h1>

              <div className="flex flex-col gap-2 mt-2">
                {Community.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                    >
                      <Link to={ele.split(" ").join("-").toLowerCase()}>
                        {ele}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>

          {/* Section 2 */}
          <div className="lg:w-[65%] flex flex-wrap flex-row justify-between pl-4 lg:pl-16 gap-3">
            {FooterLink2.map((ele, i) => {
              return (
                <div key={i} className="w-[48%] lg:w-[30%] mb-7 lg:pl-0">
                  <h1 className="text-richblack-50 font-semibold text-[16px]">
                    {ele.title}
                  </h1>
                  <div className="flex flex-col gap-2 mt-2">
                    {ele.links.map((link, index) => {
                      return (
                        <div
                          key={index}
                          className="text-[14px] cursor-pointer hover:text-richblack-50 transition-all duration-200"
                        >
                          <Link to={link.link}>{link.title}</Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between w-11/12 max-w-maxContent text-richblack-400 mx-auto  pb-14 text-sm">
        {/* Section 1 */}
        <div className="flex justify-between lg:items-start items-center flex-col lg:flex-row gap-6 w-full">
          <div className="flex flex-row  flex-wrap gap-5 ">
            {BottomFooter.map((ele, i) => {
              return (
                <div
                  key={i}
                  className={` ${
                    BottomFooter.length - 1 === i
                      ? ""
                      : "  border-r border-richblack-700 cursor-pointer hover:text-richblack-50 transition-all duration-200"
                  } px-3 `}
                >
                  <Link to={`${ele.path}`}>{ele.name}</Link>
                </div>
              );
            })}
          </div>

          <div className="text-center">Made with ❤️ @2024 HTS Learn </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
