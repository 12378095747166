// import React from  react 
import Footer from "../../components/common/Footer";
import "../footerStyle.css";
const JavaScript = () => {
  return (
 <>
       <div className="main">
      <h2 className="heading">JavaScript Language Overview</h2>
      <ol>
        <li>
          <h3 className="subHeading">Client-Side Scripting:</h3> JavaScript is primarily used
          as a scripting language for client-side web development. It runs in
          the browser and allows dynamic interaction with HTML and CSS.
        </li>
        <li>
          <h3 className="subHeading">High-level & Interpreted:</h3> JavaScript is a high-level
          language that is interpreted by the browser s JavaScript engine (e.g.,
          V8 in Chrome, SpiderMonkey in Firefox).
        </li>
        <li>
          <h3 className="subHeading">Dynamic & Weakly Typed:</h3> JavaScript is dynamically
          typed, meaning variable types are determined at runtime. It s also
          weakly typed, allowing flexibility but requiring careful handling of
          data types.
        </li>
        <li>
          <h3 className="subHeading">Event-Driven & Asynchronous:</h3> JavaScript uses
          event-driven programming and supports asynchronous operations through
          callbacks, promises, and async/await syntax, making it efficient for
          handling user interactions and network requests.
        </li>
        <li>
          <h3 className="subHeading">Functional & Object-Oriented:</h3> JavaScript supports
          both functional programming paradigms (e.g., higher-order functions,
          closures) and object-oriented programming (e.g., objects, prototypes).
        </li>
        <li>
          <h3 className="subHeading">Modern ECMAScript Standards:</h3> JavaScript evolves with
          ECMAScript standards. Features like arrow functions, classes, modules,
          and destructuring have been introduced to improve code readability and
          maintainability.
        </li>
        <li>
          <h3 className="subHeading">Libraries & Frameworks:</h3> JavaScript has a vast
          ecosystem of libraries (e.g., React, Vue.js) and frameworks (e.g.,
          Angular, Node.js) for front-end and server-side development, enabling
          rapid application development.
        </li>
        <li>
          <h3 className="subHeading">Application Areas:</h3> JavaScript is used extensively in
          web development (both front-end and server-side with Node.js),
          building interactive web interfaces, single-page applications (SPAs),
          mobile applications (React Native), and server-side applications.
        </li>
      </ol>

      <h3 className="subHeading">Example: Hello, World! Program in Browser Console</h3>
      <pre>
        {`
// JavaScript code running in browser console
console.log( Hello, World! );
        `}
      </pre>

      <p>
        This simple JavaScript example demonstrates outputting <q>Hello, World!</q>
        to the browser console using the <code>console.log()</code> function. It
        showcases JavaScript s immediate feedback and its role in client-side
        scripting.
      </p>
    </div>

    <Footer />
 </>
  );
};

export default JavaScript;
