// import React from  react 
import Footer from "../../components/common/Footer";
import "../footerStyle.css";

const Java = () => {
  return (
   <>
     <div className="main">
      <h2 className="heading">Java Language Overview</h2>
      <ol>
        <li>
          <h3 className="subHeading">Platform Independence:</h3> Java programs can run on any
          device or operating system that has a Java Virtual Machine (JVM), due
          to its bytecode compilation and execution model.
        </li>
        <li>
          <h3 className="subHeading">Object-Oriented:</h3> Java is fundamentally
          object-oriented, emphasizing classes and objects, encapsulation,
          inheritance, and polymorphism.
        </li>
        <li>
          <h3 className="subHeading">Rich Standard Library:</h3> Java comes with a
          comprehensive Java API providing pre-built classes and methods for
          common tasks like I/O operations, networking, and data structures.
        </li>
        <li>
          <h3 className="subHeading">Automatic Memory Management:</h3> Java features automatic
          garbage collection, which manages memory allocation and deallocation,
          reducing the risk of memory leaks.
        </li>
        <li>
          <h3 className="subHeading">Security:</h3> Java prioritizes security with features
          like bytecode verification, preventing unauthorized access and
          execution of malicious code.
        </li>
        <li>
          <h3 className="subHeading">Multithreading:</h3> Java supports multithreading,
          enabling concurrent execution of tasks within a program, crucial for
          responsive and efficient applications.
        </li>
        <li>
          <h3 className="subHeading">Community and Ecosystem:</h3> Java has a large community
          and ecosystem, supported by Oracle and open-source contributors,
          offering extensive libraries, frameworks (e.g., Spring), and
          development tools (e.g., IntelliJ IDEA).
        </li>
        <li>
          <h3 className="subHeading">Application Areas:</h3> Java is widely used in web
          development (Java EE, Spring), mobile development (Android),
          enterprise systems, scientific computing, and more, due to its
          scalability and versatility.
        </li>
      </ol>

      <h3 className="subHeading">Example: Hello, World! Program</h3>
      <pre>
        {`
public class HelloWorld {
  public static void main(String[] args) {
      System.out.println("Hello, World!");
  }
}
      `}
      </pre>

      <p>
        This simple Java program prints <q>Hello, World!</q> to the console. It
        demonstrates Java s syntax and structure, emphasizing its
        straightforward approach to getting started with programming.
      </p>
    </div>

    <Footer />
   </>
  );
};

export default Java;
