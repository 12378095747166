// import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";

// import { Player } from "@lottiefiles/react-lottie-player";
// import frameImg from "../../../assets/Images/frame.png";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import LottieAnimation from "../../common/LottieAnimation";

function Template({ title, description1, description2, condition, formType }) {
  const { loading } = useSelector((state) => state.auth);
  // console.log(condition)

  return (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center ">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="   flex w-11/12 max-w-maxContent  flex-col-reverse lg:justify-between gap-y-12 py-12 md:flex-row md:gap-y-0 md:gap-x-12 lg:pr-20">
          <div className="   mx-auto w-11/12 max-w-[450px] md:mx-0">
            <h1 className="text-[1.875rem] font-semibold leading-[2.375rem] text-richblack-5">
              {title}
            </h1>
            <p className="mt-4 text-[1.125rem] leading-[1.625rem]">
              <span className="text-richblack-100">{description1}</span>{" "}
              <span className="font-edu-sa font-bold italic text-blue-100">
                {description2}
              </span>
            </p>
            {formType === "signup" ? <SignupForm /> : <LoginForm />}
          </div>
          <div className=" relative mx-auto w-11/12 max-w-[450px] md:mx-0 ">
            {/* <div className="overflow-hidden  ">
              <Player
                className="scale-125"
                autoplay
                loop
                src={img}
                style={{ height: '500px', width: '700px' }}
              ></Player>
            </div> */}
            {/* <img
              src={frameImg}
              alt="Pattern"
              width={558}
              height={504}
              loading="lazy"
            /> */}
            <LottieAnimation value={condition} />
            {/* <img
              src={image}
              alt="Students"
              width={558}
              height={504}
              loading="lazy"
              className="absolute -top-4 right-4 z-10"
            /> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Template;
