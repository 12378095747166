// import React from "react";

import "./style.css";

const HelpCenter = () => {
  return (
    <div className="root">
      <div className="main">
        <h1 className="heading">Help Center </h1>

        <ul>
          <li>
            Search Bar: Allow users to search for specific topics or issues.
          </li>
          <li>
            Categories: Organize the help content into easily navigable
            categories.
          </li>
          <li>FAQs: Address common questions.</li>
          <li>
            Tutorials and Guides: Step-by-step guides on using the platform.
          </li>
          <li>Troubleshooting: Solutions to common technical issues.</li>
          <li>
            Contact Support: Information on how to reach out for additional
            help.
          </li>
          <li>Example Categories</li>
          <li>Getting Started</li>
        </ul>

        <h2 className="subheading">Creating An Account</h2>
        <ul>
          <li>Navigating the platform</li>
          <li>Enrolling in a course</li>
          <li>Account Management</li>
        </ul>

        <h2 className="subheading">Updating Profile Information</h2>
        <ul>
          <li>Password recovery</li>
          <li>Subscription and billing</li>
          <li>Course Participation</li>
        </ul>

        <h2 className="subheading">
          Joining Live Classes -<q> coming soon </q>
        </h2>

        <ul>
          <li>Accessing recorded sessions</li>
          <li>Submitting assignments</li>
          <li>Technical Issues</li>
        </ul>

        <h2 className="subheading">
          Video playback problems - <q> Coming Soon </q>
        </h2>
        <ul>
          <li>Audio issues during live sessions</li>
          <li>Browser compatibility</li>
          <li>Instructor Resources</li>
        </ul>

        <h2 className="subheading">Creating and managing courses</h2>
        <ul>
          <li>Uploading materials</li>
          <li>Communicating with students</li>
          <li>Policies and Guidelines</li>
        </ul>
      </div>
    </div>
  );
};

export default HelpCenter;
