// import React from 'react'
// import HighlightText from '../HomePage/HighlightText'

const Quote = () => {
  return (
    <div className=" text-xl md:text-4xl font-semibold mx-auto py-5 pb-20 text-center text-white">
        We are passionate about revolutionizing the way we learn. 
        <br></br>
         Our combines technology expertise , and community to create an  unparalleled educational
         experience.
    </div>
  )
}

export default Quote
{/* innovative platform <HighlightText text={"combines technology"} />,{" "} */}
{/* <span className="bg-gradient-to-b from-[#FF512F] to-[#F09819] text-transparent bg-clip-text font-bold">
    {" "}
    expertise
</span> */}

{/* <span className="bg-gradient-to-b from-[#E65C00] to-[#F9D423] text-transparent bg-clip-text font-bold">
    {" "}
    unparalleled educational
experience.
</span>  */}