// About.jsx 

// import React from "react"
import FoundingStory from "../assets/Images/FoundingStory.jpeg"
import BannerImage1 from "../assets/Images/aboutus1.webp"
import BannerImage2 from "../assets/Images/aboutus2.webp"
import BannerImage3 from "../assets/Images/aboutus3.webp"
import Footer from "../components/common/Footer"
// import ReviewSlider from "../components/common/ReviewSlider"
import ContactFormSection from "../components/core/AboutPage/ContactFormSection"
import LearningGrid from "../components/core/AboutPage/LearningGrid"
import Quote from "../components/core/AboutPage/Quote"
import StatsComponenet from "../components/core/AboutPage/Stats"
// import HighlightText from "../components/core/HomePage/HighlightText"

const About = () => {
  return (
    <div>
      <section className="bg-richblack-700">
        <div className="relative mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-center text-white">
          <header className="mx-auto py-20 text-4xl font-semibold lg:w-[70%]">
            Driving Innovation in Online Education for a <strong className="text-pure-greys-25">Brighter Future</strong>
            {/* <HighlightText text={"Brighter Future"} /> */}

            <p className="mx-auto mt-3 text-center text-base font-medium text-richblack-300 lg:w-[95%]">
              HTS Learn is at the forefront of driving innovation in online
              education. We are passionate about creating a brighter future by
              offering cutting-edge courses, leveraging emerging technologies,
              and nurturing a vibrant learning community.
            </p>
          </header>
          <div className="sm:h-[70px] lg:h-[150px]"></div>
          <div className="absolute bottom-0 left-[50%] grid w-[100%] translate-x-[-50%] translate-y-[30%] grid-cols-3 gap-3 lg:gap-5">
            <img src={BannerImage1} alt="" />
            <img src={BannerImage2} alt="" />
            <img src={BannerImage3} alt="" />
          </div>
        </div>
      </section>

      <section className="border-b border-richblack-700">
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-500">
          <div className="h-[100px] "></div>
          <Quote />
        </div>
      </section>

      <section>
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-500">
          <div className="flex flex-col items-center gap-10 lg:flex-row justify-between">
            <div className="my-24 flex lg:w-[50%] flex-col gap-10">
              <h1 className="text-white text-4xl font-semibold text-transparent lg:w-[70%] ">
                Our Founding Story
              </h1>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
              HTS Learn, established in March 2023, is a software training institute in Noida, India. They provide online courses and programs in technology, software development, and career-related skills, empowering individuals and businesses to build their knowledge and expertise.
              </p>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                As experienced educators ourselves, we witnessed firsthand the
                limitations and challenges of traditional education systems. We
                believed that education should not be confined to the walls of a
                classroom or restricted by geographical boundaries. We
                envisioned a platform that could bridge these gaps and empower
                individuals from all walks of life to unlock their full
                potential.
              </p>
            </div>

            <div className="w-[450px] overflow-hidden border border-white">
              <img
                src={FoundingStory}
                alt=""
                className="shadow-[0_0_20px_0] shadow-blue-200 scale-[1.05]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center lg:gap-10 lg:flex-row justify-between">
            <div className="my-24 flex lg:w-[40%] flex-col gap-10">
              <h1 className="bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] text-white">
                Our Vision
              </h1>
              <p className="text-base font-medium text-richblack-100 lg:w-[95%] text-justify ">
              HTS Learn envisions revolutionizing education by bridging the gap between theoretical knowledge and practical application in the IT sector. They aim to become a leading training institute that shapes the future of technology by nurturing talent and fostering innovation. Their vision is to create a dynamic learning environment that adapts to the evolving technological landscape, ensuring students and professionals are equipped with the skills needed to excel globally. By fostering a culture of continuous improvement, HTS Learn aspires to contribute significantly to the growth and development of the global technology community.
              </p>
            </div>
            <div className="my-24 flex lg:w-[40%] flex-col gap-10">
              <h1 className="text-transparent bg-clip-text text-4xl font-semibold lg:w-[70%] text-white">
              Our Mission
              </h1>
              <p className="text-base font-medium text-richblack-100 lg:w-[95%] text-justify">
              The mission of HTS Learn is to provide high-quality IT training that meets industry standards and addresses the skills gap in the workforce. They focus on delivering comprehensive and practical education through a variety of programs, including classroom training, industrial training, campus training, and corporate training. HTS Learn is committed to empowering learners with the latest technological skills and knowledge, enhancing their employability and career growth. By fostering a culture of continuous learning and development, they strive to support individuals in achieving their professional goals and contribute to the advancement of the tech industry
              </p>
            </div>
          </div>
        </div>
      </section>

      <StatsComponenet />
      <section className="mx-auto mt-20 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-white">
        <LearningGrid />
        <ContactFormSection />
      </section>

      <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-richblack-900 text-white">
        {/* Reviws from Other Learner */}
        {/* <h1 className="text-center text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1> */}
        {/* <ReviewSlider /> */}
      </div>
      
      <Footer />
    </div>
  )
}

export default About
