import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import HighlightText from "../components/core/HomePage/HighlightText";
import CTAButton from "../components/core/HomePage/Button";
// import Banner from "../assets/homeVideo.mp4";
// import elipseImage1 from "../assets/Images/Ellipse 1.png";
// import elipseImage2 from "../assets/Images/Ellipse 2.png";
// import elipseImage3 from "../assets/Images/Ellipse 3.png";
import CodeBlocks from "../components/core/HomePage/CodeBlocks";
import "./Home.css";
import TimelineSection from "../components/core/HomePage/TimelineSection";
import LearningLanguageSection from "../components/core/HomePage/LearningLanguageSection";
import InstructorSection from "../components/core/HomePage/InstructorSection";
import Footer from "../components/common/Footer";
import ExploreMore from "../components/core/HomePage/ExploreMore";
import TeamInfo from "../components/core/HomePage/TeamInfo";
// import ReviewSlider from "../components/common/ReviewSlider";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lottie from "lottie-react";

import animationData1 from "../assets/herogif/HERO 1.json";
import animationData2 from "../assets/herogif/img2.json";
// import animationData3 from "../assets/herogif/HERO 3.json";

const animations = [animationData1, animationData2];

import { useEffect, useState } from "react";
import "../App.css";
import { useForm } from "react-hook-form";

import CountryCode from "../data/countrycode.json";
import { apiConnector } from "../services/apiconnector";
import { contactusEndpoint } from "../services/apis";
import { toast } from "react-hot-toast";

const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const submitContactForm = async (data) => {
    console.log("Form Data - ", data);
    try {
      setLoading(true);
      const res = await apiConnector(
        "POST",
        contactusEndpoint.CONTACT_Demo_API,
        data
      );
      // console.log("Email Res - ", res)
      if (res.data.success) {
        toast.success("Your Request Successfully Submitted ");
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR MESSAGE - ", error.message);
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        name: "",
        phone: "",
      });
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <div>
      {/* SECTION-1 */}
      <div className="relative mx-auto flex flex-col w-11/12 items-center max-w-maxContent text-white ">
        <div className=" w-[100vw] lg:h-[100vh] flex lg:flex-row flex-col">
          <div className=" lg:w-[60%]  flex flex-col items-center justify-center">
            <div className=" lg:w-[500px] lg:h-[500px] w-full mt-10 lg:mt-0 ">
              <Slider {...settings}>
                {animations.map((animation, index) => (
                  <div key={index}>
                    <Lottie
                      className=""
                      animationData={animation}
                      autoplay={true}
                      loop={true}
                    />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="text-center text-3xl font-semibold ">
              Revolutionize Education with{" "}
              <span className="hover:text-[#F83232] cursor-pointer">
                IT Training
              </span>
            </div>

            {/* <div className=" mt-4 w-[90%] text-center text-lg font-bold text-richblack-300">
              With our online coding courses, you can learn at your own pace,
              from anywhere in the world, and get access to a wealth of
              resources, including hands-on projects, quizzes, and personalized
              feedback from instructors.
            </div> */}
            <Link to={"/signup"}>
              <div
                className=" group mt-[3vh] p-1 mx-auto rounded-full text-black font-bold bg-white
            transition-all duration-200 hover:scale-95 w-fit shadow-sm shadow-pure-greys-100"
              >
                <div
                  className="flex flex-row items-center gap-2 rounded-full px-10 py-[5px]
                transition-all duration-200 group-hover:bg-richblack-900 hover:text-white"
                >
                  <p>Check out the Courses</p>
                  <FaArrowRight />
                </div>
              </div>
            </Link>
          </div>

          <div className="  lg:w-[40%] w-[100%] flex flex-col justify-center items-center  gap-6 p-10 h-full">
            <div className="border-container rounded-3xl">
              <div className=" rounded-3xl lg:px-10 px-4 lg:py-20  py-10 bg-[#161D29]">
                <h2 className="text-center mb-5">
                  Book a free <span>demo</span>
                </h2>
                <form
                  className=" flex flex-col gap-6 "
                  onSubmit={handleSubmit(submitContactForm)}
                >
                  <div className="flex flex-col gap-2 ">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Your Full Name "
                      className="form-style"
                      {...register("name", { required: true })}
                    />
                    {errors.firstname && (
                      <span className="-mt-1 text-[12px] text-yellow-100">
                        Please enter your name.
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2  items-center">
                      <div className="flex w-[78px]  flex-col gap-2">
                        <select
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="Enter first name"
                          className="form-style "
                          {...register("countrycode", { required: true })}
                        >
                          {CountryCode.map((ele, i) => {
                            return (
                              <option key={i} value={ele.code}>
                                {ele.code} -{ele.country}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="flex w-[calc(100%-90px)] flex-col gap-2">
                        <input
                          type="tel"
                          name="phonenumber"
                          id="phonenumber"
                          placeholder="Phone Number"
                          className="form-style"
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Please enter your Phone Number.",
                            },
                            maxLength: {
                              value: 12,
                              message: "Invalid Phone Number",
                            },
                            minLength: {
                              value: 10,
                              message: "Invalid Phone Number",
                            },
                          })}
                        />
                      </div>
                    </div>
                    {errors.phoneNo && (
                      <span className="-mt-1 text-[12px] text-yellow-100">
                        {errors.phoneNo.message}
                      </span>
                    )}
                  </div>
                  <div
                    className="flex gap-2 w-full bg-white text-black
                  py-2 rounded-3xl items-center justify-center"
                  >
                    <button type="submit" className="">
                      Apply Now
                    </button>
                    <FaArrowRight />
                  </div>
                </form>
                {loading && <div className="mx-auto spinner"></div>}
              </div>
            </div>

            <div className="flex flex-row gap-4 md:gap-7 mt-4 md:mt-10">
              <CTAButton active={false} linkto={"/signup"}>
                Learn More
              </CTAButton>

              <CTAButton
                active={false}
                linkto={
                  "https://api.whatsapp.com/send/?phone=919354043990&text&type=phone_number&app_absent=0"
                }
              >
                Join WhatsApp
              </CTAButton>
            </div>
          </div>
        </div>

        <div className=" flex flex-col relative items-center gap-7  lg:mt-32 lg:mb-10 ">
          <video muted loop autoPlay className="mx-3">
            <source
              src="https://res.cloudinary.com/defdiimmj/video/upload/v1722254150/Hts/xzh0wga14wcxa2ubfue0.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <div>
          <CodeBlocks
            position={"lg:flex-row"}
            // imgPos = {"-top-[40%] right-[8%]"}
            heading={
              <div className="text-4xl font-semibold">
                Unlock Your
                <HighlightText text={"coding potential"} /> with our online
                courses
              </div>
            }
            subheading={
              "Our courses are designed and taught by industry experts who have years of experience in coding and are passionate about sharing their knowledge with you."
            }
            ctabtn1={{
              btnText: "Try it Yourself",
              linkto: "/signup",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              linkto: "/login",
              active: false,
            }}
            codeblock={[
              `<!DOCTYPE html>
              <html>\nhead><title>HTS Learn</title></head>`,
              ` <body>
             <h1>You can get your dream Job with the support of HTS Learn</h1> \n </body>\n</html> `,
            ]}
            codeColor={"text-yellow-25"}
            // backgroudGradient={elipseImage3}
            backgroudGradient={<div className="codeblock1 absolute"></div>}
          />
        </div>

        {/* CODE-SECTION-2 */}
        <div>
          <CodeBlocks
            position={"lg:flex-row-reverse"}
            // imgPos = {"-top-[40%] right-[66%]"}
            heading={
              <div className="text-4xl font-semibold">
                Start
                <HighlightText text={`coding`} />
                <br />
                <HighlightText text={` in seconds`} />
              </div>
            }
            subheading={
              "Go ahead, give it a try. Our hands-on learning environment means you'll be writing real code from your very first lesson."
            }
            ctabtn1={{
              btnText: "Continue Lesson",
              linkto: "/signup",
              active: true,
            }}
            ctabtn2={{
              btnText: "learn more",
              linkto: "/login",
              active: false,
            }}
            codeblock={[
              `<!DOCTYPE html>
              <html>\n<head><title>HTS LEARN</title> </head>`,
              `<body>
              <h1><a href="www.htslearn.com">HTS Learn </a> that help to get your dream Job </h1>`,
              `<h1>We provide 100% Job Guarantee and 100% refund </h1> </body>`,
            ]}
            codeColor={"text-yellow-25"}
            // backgroudGradient={elipseImage2}
            backgroudGradient={<div className="codeblock2 absolute"></div>}
          />
        </div>
        <ExploreMore />
      </div>

      {/* Section 2 */}
      <div className="bg-pure-greys-5 text-richblack-700">
        <div className="homepage_bg h-[320px]">
          {/* Explore Full Catagory Section */}
          <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8">
            <div className="lg:h-[150px]"></div>
            <div className="flex flex-row gap-7 text-white lg:mt-8">
              <CTAButton active={true} linkto={"/signup"}>
                <div className="flex items-center gap-2">
                  Explore Full Catalog
                  <FaArrowRight />
                </div>
              </CTAButton>
              <CTAButton active={false} linkto={"/login"}>
                Learn More
              </CTAButton>
            </div>
          </div>
        </div>

        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 ">
          {/* Job that is in Demand - Section 1 */}
          <div className="mb-10 mt-[-100px] flex flex-col justify-between gap-7 lg:mt-20 lg:flex-row lg:gap-0">
            <div className="text-4xl font-semibold lg:w-[45%] ">
              Get the skills you need for a{" "}
              <HighlightText text={"job that is in demand."} />
            </div>
            <div className="flex flex-col items-start gap-10 lg:w-[40%]">
              <div className="text-[16px]">
                Modern HTS Learn sets its own terms. Today, to be a competitive
                specialist requires more than professional skills.
              </div>
              <CTAButton active={true} linkto={"/signup"}>
                <div className="">Learn More</div>
              </CTAButton>
            </div>
          </div>

          {/* Timeline Section - Section 2 */}
          <TimelineSection />

          {/* Learning Language Section - Section 3 */}
          <LearningLanguageSection />
        </div>
      </div>

      {/* Section 3 */}
      <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center justify-between gap-8 bg-richblack-900 text-white">
        {/* Become a instructor section */}
        <InstructorSection />

        {/* Reviws from Other Learner */}
        {/* <h1 className="text-center text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1> */}
        {/* <ReviewSlider /> */}
      </div>
      <TeamInfo />

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Home;
